<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <div class="">
              <h3>Total de testimonios</h3> <small> (Hay {{ totalTestimonials }} testimonios)</small>
            </div>
          </b-col>

          <!-- Search -->
          <!--          <b-col-->
          <!--            cols="12"-->
          <!--            md="6"-->
          <!--          >-->
          <!--            <div class="d-flex align-items-center justify-content-end">-->
          <!--              <b-button-->
          <!--                variant="primary"-->
          <!--                @click="isAddNewUserSidebarActive = true"-->
          <!--              >-->
          <!--                <span class="text-nowrap">Crear</span>-->
          <!--              </b-button>-->
          <!--            </div>-->
          <!--          </b-col>-->
        </b-row>

      </div>

      <b-table
        ref="refTestimonialListTable"
        class="position-relative"
        :items="fetchTestimonials"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No se encontraron registros coincidentes"
      >

        <!-- Column: Client -->
        <template #cell(client)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                variant="light-primary"
              />
            </template>

            <b-link
                :to="{ name: 'contacts-detail', params: { id: data.item.contact_id } }"
                class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.client }}
            </b-link>

          </b-media>
        </template>

        <!-- Column: Client -->
        <template #cell(photo)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.photo"
              />
            </template>
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`invoice-row-${data.item.id}-send-icon`"
              icon="EditIcon"
              class="cursor-pointer"
              size="16"
              @click="$router.push({ name: 'contacts-detail', params: { id: data.item.contact_id }})"
            />
            <b-tooltip
              title="Editar"
              class="cursor-pointer"
              :target="`invoice-row-${data.item.id}-send-icon`"
            />

            <feather-icon
              :id="`invoice-row-${data.item.id}-preview-icon`"
              icon="TrashIcon"
              size="16"
              class="mx-1 cursor-pointer"
              @click="deleteTestimonial(data.item)"
            />
            <b-tooltip
              title="Eliminar"
              :target="`invoice-row-${data.item.id}-preview-icon`"
            />
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-end justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalTestimonials"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BMedia, BAvatar, BPagination, BTooltip,BLink,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import testimonialStoreModule from '@/views/dramox/testimonial/testimonialStoreModule'
import router from '@/router'
import testimonialHandler from '@/views/dramox/testimonial/testimonialHandler'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BAvatar,
    BPagination,
    BTooltip,
    BLink,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-testimonials'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, testimonialStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const showNotification = ({ title, icon, variant }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }

    const {
      // fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalTestimonials,
      perPageOptions,
      searchQuery,
      refTestimonialListTable,
      refetchData,
      fetchTestimonials,

    } = testimonialHandler()

    const deleteTestimonial = item => {

      store.dispatch('app-testimonials/deleteTestimonial', { id: item.id })
        .then(() => {
          refetchData()
          showNotification({ title: 'Testimonio eliminado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        }).catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    return {

      // fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalTestimonials,
      perPageOptions,
      searchQuery,
      refTestimonialListTable,
      refetchData,
      fetchTestimonials,

      deleteTestimonial,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
