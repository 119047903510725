import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTestimonials(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/testimonials', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addTestimonial(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/testimonial/store', { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteTestimonial(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/admin/testimonial/${id}/delete`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
